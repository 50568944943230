import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setAccessToken } from 'src/app/store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class RehydrationService {

  constructor(private store: Store) {}

  rehydrateAuthState() {
    const token = sessionStorage.getItem('accessToken');
    if (token) {
      this.store.dispatch(setAccessToken({ accessToken: token }));
    }
  }
}
