<div class="mainContainer">
  <div *ngIf="dangerAlert" class="alert alert-danger  alert-dismissible fade show" role="alert">
    <strong>Invalid authentication. Please verify the environment.!</strong>
    <button (click)="closeDangerAlert()" type="button" class="btn-close" data-bs-dismiss="alert"
      aria-label="Close"></button>
  </div>
  <div class="card-container">
    <a routerLink="/my-orders">
      <div class="card">
        <div class="card-title">
          <p>My Orders</p>
        </div>
        <div class="card-content">
          <p>Create new orders and search for existing ones.</p>
        </div>
      </div>
    </a>
    <a routerLink="/my-pickups">
      <div class="card">
        <div class="card-title">
          <p>My Pickups</p>
        </div>
        <div class="card-content">
          <p>Manage your pickups and print the pickup labels.</p>
        </div>
      </div>
    </a>
  </div>
</div>
