import { Component, OnInit, ViewChild } from '@angular/core';
import { PaxOrderService } from '../../services/pax-order.service';
import { NavigationEnd, Router } from '@angular/router';
import { CheckUserRolesService } from '../../services/check-user-roles.service';
import { Observable, Subscription, filter, firstValueFrom} from 'rxjs';
import { DatePipe } from '@angular/common';
import { PopupMessageComponent } from '../popup-message/popup-message.component';

@Component({
  selector: 'app-my-orders-view',
  templateUrl: './my-orders-view.component.html',
  styleUrls: ['./my-orders-view.component.scss'],
  providers: [PaxOrderService, DatePipe],
})
export class MyOrdersViewComponent implements OnInit {
  accessToken$: Observable<unknown>;

  currentPage = 1;
  totalPages: number;
  totalFoundItems: number;

  queryParameters: any = {};
  parcelNumber: string = '';
  tenantKey: string = null;
  pickUpDate: string = '';
  creationDate: string = '';
  insertTimeStampGt: any | Date = '';
  insertTimeStampLte: any | Date = '';
  pickupLocation: string = '';
  orderStatus: string = '';
  requesterName: string = '';
  requesterCountry: string = '';
  requesterReference: string = '';
  requesterLocation: string = '';
  deliveryLocation: string = '';
  selectedRequestedPickupDateGt = '';
  selectedRequestedPickupDateLte: string = '';
  requestedPickupDateGt: string = '';
  requestedPickupDateLte: string = '';
  selectedRequestedCreationDateGt = '';
  selectedRequestedCreationDateLte: string = '';
  requestedCreationDateGt: string = '';
  requestedCreationDateLte: string = '';
  isGroupAdmin: boolean = false;
  limit: number = 30;
  offset: number = 0;
  ordersList: any = [];
  adjustedFilteredOrdersList = [];
  paxOrderValdationsErrorList = [];
  dataNotLoaded: boolean = false;
  timerForTyping: any = false;
  isPaxOrderCreator: boolean = false;
  authorizationFailed: boolean = false;

  isResizing: boolean = false;
  startingX: number = 0;
  startingWidth: number = 0;
  resizingMouseMove: any;
  resizingMouseUp: any;

  currentPath: string = '';
  private subscription: Subscription;

  orderAsTemplate: any;

  expandedRows: { [key: number]: boolean } = {};
  orderStatusList: string[] = ['NEW', 'IN_PROGRESS', 'PICKED_UP', 'COMPLETE', 'CANCELLED'];
  selectedStatuses: string[] = [];
  filterChips: { key: string, value: string }[] = [];

  popoverIndex: number | null = null;

  sortingList: string[] = ["-inserttimestamp"];
  isParcelNumberDescendingSortingActive: boolean = false;
  isParcelNumberAscendingSortingActive: boolean = false;
  isCreationDateDescendingSortingActive: boolean = true;
  isCreationDateAscendingSortingActive: boolean = false;
  isRequesterLocationDescendingSortingActive: boolean = false;
  isRequesterLocationAscendingSortingActive: boolean = false;
  isPickupLocationDescendingSortingActive: boolean = false;
  isPickupLocationAscendingSortingActive: boolean = false;
  isDeliveryLocationDescendingSortingActive: boolean = false;
  isDeliveryLocationAscendingSortingActive: boolean = false;
  activeSortings: { key: string, order: 'asc' | 'desc' }[] = [];

  constructor(private paxOrderService: PaxOrderService,
    private checkUserRolesService: CheckUserRolesService,
    private router: Router,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.loadCurrentOffsetFromLocalStorage();
    this.updateActiveSortings();
    this.loadFiltersFromLocalStorage();
    this.loadSortingChipsFromLocalStorage();

    const startDateRemoved = localStorage.getItem('creationStartDateRemoved') === 'true';
    const endDateRemoved = localStorage.getItem('creationEndDateRemoved') === 'true';

    if (!this.selectedRequestedCreationDateGt && !startDateRemoved) {
      this.selectedRequestedCreationDateGt = this.getStandardDate(-6);
      this.requestedCreationDateGt = this.getPreviousDate(this.selectedRequestedCreationDateGt);
    }
    if (!this.selectedRequestedCreationDateLte && !endDateRemoved) {
      this.selectedRequestedCreationDateLte = this.getStandardDate(0);
    }

    // Check if the date already has time portion, only append "T23:59:59Z" if it doesn't
    this.requestedCreationDateGt = this.requestedCreationDateGt
      ? (this.requestedCreationDateGt.includes('T')
          ? this.requestedCreationDateGt
          : this.requestedCreationDateGt + "T23:59:59Z")
      : '';

    this.requestedCreationDateLte = this.selectedRequestedCreationDateLte
      ? (this.selectedRequestedCreationDateLte.includes('T')
          ? this.selectedRequestedCreationDateLte
          : this.selectedRequestedCreationDateLte + "T23:59:59Z")
      : '';

    this.tenantKey = this.checkUserRolesService.getTenantKey();
    this.isGroupAdmin = this.checkUserRolesService.isGroupAccount;
    this.isPaxOrderCreator = this.checkUserRolesService.getIsPaxOrderCreator();
    this.getCurrentPath();
    this.updateTable();
}


  getCurrentPath() {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let navigationEvent = event as NavigationEnd;
      this.currentPath = navigationEvent.url;
    });
  }

  setQueryParameters() {
    if (this.parcelNumber !== '') {
      this.queryParameters['parcel-key'] = this.parcelNumber.trim();
    }

    if (this.requestedCreationDateLte !== '') {
      this.queryParameters['insert-timestamp-lte'] = this.requestedCreationDateLte.trim();
    }

    if (this.requestedCreationDateGt !== '') {
      this.queryParameters['insert-timestamp-gt'] = this.requestedCreationDateGt.trim();
    }

    if (this.requestedPickupDateLte !== '') {
      this.queryParameters['requested-pickup-date-lte'] = this.requestedPickupDateLte.trim();
    }

    if (this.requestedPickupDateGt !== '') {
      this.queryParameters['requested-pickup-date-gt'] = this.requestedPickupDateGt.trim();
    }

    if (this.pickupLocation !== '') {
      this.queryParameters['pickup-location'] = this.pickupLocation.trim();
    }

    if (this.requesterName !== '') {
      this.queryParameters['requester-name'] = this.requesterName.trim();
    }

    if (this.requesterReference !== '') {
      this.queryParameters['requester-reference'] = this.requesterReference.trim();
    }

    if (this.requesterLocation !== '') {
      this.queryParameters['requester-location'] = this.requesterLocation.trim();
    }

    if (this.deliveryLocation !== '') {
      this.queryParameters['delivery-location'] = this.deliveryLocation.trim();
    }

    if (this.orderStatus !== '') {
      this.queryParameters['operational-order-status'] = this.orderStatus;
    }

    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.queryParameters['sort'] = this.sortingList;
    if (!this.isGroupAdmin) {
      this.queryParameters['requester-tenant-keys'] = this.tenantKey;
    }

    this.updateDisplayFilterChips();
    this.saveFiltersToLocalStorage();
  }

  updateDisplayFilterChips() {
    this.filterChips = [];

    const parametersToCheck = [
      { key: 'Parcel Number', value: this.parcelNumber },
      { key: 'Creation Date - Start', value: this.selectedRequestedCreationDateGt ? this.datePipe.transform(this.selectedRequestedCreationDateGt, 'dd.MM.yyyy') : '' },
      { key: 'Creation Date - End', value: this.selectedRequestedCreationDateLte ? this.datePipe.transform(this.selectedRequestedCreationDateLte, 'dd.MM.yyyy') : '' },
      { key: 'Pick-up Date - Start', value: this.selectedRequestedPickupDateGt ? this.datePipe.transform(this.selectedRequestedPickupDateGt, 'dd.MM.yyyy') : '' },
      { key: 'Pick-up Date - End', value: this.selectedRequestedPickupDateLte ? this.datePipe.transform(this.selectedRequestedPickupDateLte, 'dd.MM.yyyy') : '' },
      { key: 'Pick-up Location', value: this.pickupLocation },
      { key: 'Requester Name', value: this.requesterName },
      { key: 'Requester Reference', value: this.requesterReference },
      { key: 'Requester Location', value: this.requesterLocation },
      { key: 'Delivery Location', value: this.deliveryLocation },
    ];

    // Add individual status chips
    this.selectedStatuses.forEach(status => {
      this.filterChips.push({ key: 'Order Status', value: status });
    });

    parametersToCheck.forEach(param => {
      if (param.value && param.value !== '') {
        this.filterChips.push({ key: param.key, value: param.value });
      }
    });
  }

  removeFilterChip(chip: { key: string, value: string }) {
    this.resetPagination();
    if (chip.key === 'Remove All Filters') {
      this.removeAllFilters();
      return;
    }

    switch (chip.key) {
      case 'Parcel Number':
        this.parcelNumber = '';
        break;
      case 'Creation Date - Start':
        this.selectedRequestedCreationDateGt = '';
        this.requestedCreationDateGt = '';
        localStorage.setItem('creationStartDateRemoved', 'true');
        break;
      case 'Creation Date - End':
        this.requestedCreationDateLte = '';
        this.selectedRequestedCreationDateLte = '';
        localStorage.setItem('creationEndDateRemoved', 'true');
        break;
      case 'Pick-up Date - Start':
        this.selectedRequestedPickupDateGt = '';
        this.requestedPickupDateGt = '';
        break;
      case 'Pick-up Date - End':
        this.selectedRequestedPickupDateLte = '';
        this.requestedPickupDateLte = '';
        break;
      case 'Pick-up Location':
        this.pickupLocation = '';
        break;
      case 'Requester Name':
        this.requesterName = '';
        break;
      case 'Requester Reference':
        this.requesterReference = '';
        break;
      case 'Requester Location':
        this.requesterLocation = '';
        break;
      case 'Delivery Location':
        this.deliveryLocation = '';
        break;
      case 'Order Status':
        this.selectedStatuses = this.selectedStatuses.filter(status => status !== chip.value);
        this.orderStatus = this.selectedStatuses.join(',');
        break;
    }

    this.updateDisplayFilterChips();
    this.updateTable();
    this.saveFiltersToLocalStorage();
  }

  removeAllFilters() {
    this.resetPagination();
    this.parcelNumber = '';
    this.selectedRequestedCreationDateGt = '';
    this.selectedRequestedCreationDateLte = '';
    this.requestedCreationDateGt = '';
    this.requestedCreationDateLte = '';
    this.selectedRequestedPickupDateGt = '';
    this.selectedRequestedPickupDateLte = '';
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';
    this.pickupLocation = '';
    this.requesterName = '';
    this.requesterReference = '';
    this.requesterLocation = '';
    this.deliveryLocation = '';
    this.selectedStatuses = [];
    this.orderStatus = '';

    localStorage.removeItem('orderFilters');
    localStorage.removeItem('creationStartDateRemoved');
    localStorage.removeItem('creationEndDateRemoved');
    localStorage.setItem('creationStartDateRemoved', 'true');
    localStorage.setItem('creationEndDateRemoved', 'true');

    this.updateDisplayFilterChips();
    this.updateTable();
    this.saveFiltersToLocalStorage();
  }


  saveFiltersToLocalStorage() {
    const filters = {
      parcelNumber: this.parcelNumber,
      selectedRequestedCreationDateGt: this.selectedRequestedCreationDateGt,
      selectedRequestedCreationDateLte: this.selectedRequestedCreationDateLte,
      requestedCreationDateGt: this.requestedCreationDateGt,
      requestedCreationDateLte: this.requestedCreationDateLte,
      selectedRequestedPickupDateGt: this.selectedRequestedPickupDateGt,
      selectedRequestedPickupDateLte: this.selectedRequestedPickupDateLte,
      requestedPickupDateGt: this.requestedPickupDateGt,
      requestedPickupDateLte: this.requestedPickupDateLte,
      pickupLocation: this.pickupLocation,
      requesterName: this.requesterName,
      requesterReference: this.requesterReference,
      requesterLocation: this.requesterLocation,
      deliveryLocation: this.deliveryLocation,
      selectedStatuses: this.selectedStatuses,
      orderStatus: this.orderStatus,
    };
    localStorage.setItem('orderFilters', JSON.stringify(filters));
  }

  loadFiltersFromLocalStorage() {
    const storedFilters = localStorage.getItem('orderFilters');
    const startDateRemoved = localStorage.getItem('creationStartDateRemoved') === 'true';
    const endDateRemoved = localStorage.getItem('creationEndDateRemoved') === 'true';

    if (storedFilters) {
      const filters = JSON.parse(storedFilters);
      this.parcelNumber = filters.parcelNumber || '';
      this.selectedRequestedCreationDateGt = startDateRemoved ? '' : (filters.selectedRequestedCreationDateGt || '');
      this.selectedRequestedCreationDateLte = endDateRemoved ? '' : (filters.selectedRequestedCreationDateLte || '');
      this.requestedCreationDateGt = startDateRemoved ? '' : (filters.requestedCreationDateGt || '');
      this.requestedCreationDateLte = endDateRemoved ? '' : (filters.requestedCreationDateLte || '');
      this.selectedRequestedPickupDateGt = filters.selectedRequestedPickupDateGt || '';
      this.selectedRequestedPickupDateLte = filters.selectedRequestedPickupDateLte || '';
      this.requestedPickupDateGt = filters.requestedPickupDateGt || '';
      this.requestedPickupDateLte = filters.requestedPickupDateLte || '';
      this.pickupLocation = filters.pickupLocation || '';
      this.requesterName = filters.requesterName || '';
      this.requesterReference = filters.requesterReference || '';
      this.requesterLocation = filters.requesterLocation || '';
      this.deliveryLocation = filters.deliveryLocation || '';
      this.selectedStatuses = filters.selectedStatuses || [];
      this.orderStatus = filters.orderStatus || '';
    }
  }

  onOrderWithMultipleParcelNumbersClick(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  getParcelStatus(item: any, parcel: string): string {
    if (!item['parcelDetails']) {
      return '';
    }
    const parcelDetail = item['parcelDetails'].find((detail: any) => detail.parcelKey === parcel);
    return parcelDetail ? parcelDetail.operationalParcelStatus : '';
  }

  updateTable(): void {
    this.currentPage = Math.floor(this.offset / this.limit) + 1;
    this.queryParameters = {};
    this.queryParameters['offset'] = this.offset;
    this.queryParameters['limit'] = this.limit;
    this.setQueryParameters();
    this.expandedRows = {};
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.dataNotLoaded = false;
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
      }, error => {
        if (error.status === 401) {
          this.authorizationFailed = true;
        }

        this.dataNotLoaded = true;
        this.ordersList = [];
        this.getAdjustedFilteredOrdersList();
        this.totalFoundItems = 0;
      });
  }

  adjustTextFieldWidth(): void {
    setTimeout(() => {
      const textFieldWrappers = document.querySelectorAll('.mat-mdc-text-field-wrapper');
      textFieldWrappers.forEach(wrapper => {
        const parent = wrapper.parentNode;
        if (parent) {
          parent.removeChild(wrapper);
          parent.appendChild(wrapper);
        }
      });
    }, 0);
  }

  pageChanged() {
    this.offset = (this.currentPage - 1) * this.limit;
    this.queryParameters = {};
    this.queryParameters['limit'] = this.limit;
    this.queryParameters['offset'] = this.offset;
    this.limit = this.queryParameters['limit'];
    this.expandedRows = {};
    this.setQueryParameters();
    this.saveCurrentOffsetToLocalStorage();
    this.paxOrderService.getOrders(this.queryParameters).subscribe(
      (response) => {
        this.ordersList = [];
        this.ordersList = response['data'];
        this.getAdjustedFilteredOrdersList();
        this.limit = response['limit'];
        this.totalFoundItems = response['total'];
        this.totalPages = Math.ceil(this.totalFoundItems / this.limit);
        this.dataNotLoaded = false;
      }, error => {
        this.dataNotLoaded = true;
      });
  }

  goToPrevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.pageChanged();
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
    this.pageChanged();
  }

  goToFirstPage(): void {
    this.resetPagination();
    this.pageChanged();
  }

  goToLastPage(): void {
    this.currentPage = this.totalPages;
    this.offset = Math.max(0, (this.totalPages - 1) * this.limit);
    this.pageChanged();
  }

  resetPagination() {
    this.currentPage = 1;
    this.offset = 0;
  }

  loadCurrentOffsetFromLocalStorage() {
    const savedOffset = localStorage.getItem('offsetForOrders');
    this.offset = savedOffset ? parseInt(savedOffset, 10) : 0;
  }

  saveCurrentOffsetToLocalStorage() {
    localStorage.setItem('offsetForOrders', String(this.offset));
  }

  /** Function to adjust filtered ordered */
  getAdjustedFilteredOrdersList() {
    this.adjustedFilteredOrdersList = [];
    for (let order of this.ordersList) {
      const parcelDetailsExist = order.parcelDetails && order.parcelDetails.length > 0;
      if(!parcelDetailsExist) {
        this.getShipItValidationErrors(order.paxOrderKey)
      }
      this.adjustedFilteredOrdersList.push({
        paxOrderKey: order.paxOrderKey,
        parcelNumber: parcelDetailsExist ? order.parcelDetails[0]['parcelKey'] : 'Pending',
        creationDate: this.dateOfInsertTimestamp(order.insertTimestamp),
        pickUpDate: order.requestedPickupDate,
        requesterName: order.requester['name'],
        requesterReference: order['requesterReference'],
        requesterLocation: order['requesterLocation'],
        pickupLocation: order['pickupLocation'],
        deliveryLocation: order['deliveryLocation'],
        orderStatus: order['operationalOrderStatus'],
        parcelDetails: parcelDetailsExist ? order.parcelDetails : [],
        statusKey: order.statusKey
      });
    }
    this.setQueryParameters();
  }

  /** Function to get only date from the response insertTimestamp */
  dateOfInsertTimestamp(insertTimestamp: any) {
    return insertTimestamp ? insertTimestamp.substr(0, 10) : '';
  }

  /** Function to trigger searching with a specific timeout. */
  onInputChange(event: any) {
    this.resetPagination();
    clearTimeout(this.timerForTyping);
    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  /** Function to get pickup date input from user */
  onChangePickUpDate() {
    this.resetPagination();
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';

    if (this.selectedRequestedPickupDateGt) {
      this.requestedPickupDateGt = this.getPreviousDate(this.selectedRequestedPickupDateGt);
      this.requestedPickupDateGt = this.datePipe.transform(this.requestedPickupDateGt, 'yyyy-MM-dd');
    }

    if (this.selectedRequestedPickupDateLte) {
      this.requestedPickupDateLte = this.datePipe.transform(this.selectedRequestedPickupDateLte, 'yyyy-MM-dd');
    }

    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  /** Function to get pickup date input from user */
  onChangeCreationDate() {
    this.resetPagination();
    this.requestedCreationDateGt = '';
    this.requestedCreationDateLte = '';

    if (this.selectedRequestedCreationDateGt) {
      this.requestedCreationDateGt = this.getPreviousDate(this.selectedRequestedCreationDateGt);
      this.requestedCreationDateGt = this.datePipe.transform(this.requestedCreationDateGt, 'yyyy-MM-dd') + "T23:59:59Z";
      localStorage.removeItem('creationStartDateRemoved');
    }

    if (this.selectedRequestedCreationDateLte) {
      this.requestedCreationDateLte = this.datePipe.transform(this.selectedRequestedCreationDateLte, 'yyyy-MM-dd') + "T23:59:59Z";
      localStorage.removeItem('creationEndDateRemoved');
    }

    this.timerForTyping = setTimeout(() => {
      this.updateTable();
    }, 600);
  }

  /** Function to return the previous date */
  getPreviousDate(date: string) {
    let mainDate = new Date(date);
    let previousDay = new Date(mainDate.setDate(mainDate.getDate() - 1));
    let day: any = previousDay.getDate();
    let month: any = previousDay.getMonth() + 1;
    let year = previousDay.getFullYear();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return year + "-" + month + "-" + day;
  }

  /** Function to go to createPaxOrder Page and use orders as a template */
  useExistingOrderAsTemplate(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("paxOrderToUseAsTemplate", JSON.stringify(selectedOrder[0]));
    localStorage.setItem("fromTemplateAction", "true");
    this.router.navigate(['/create-order']);
  }

  resetPickupDatePicker() {
    this.resetPagination();
    this.requestedPickupDateGt = '';
    this.requestedPickupDateLte = '';
    this.selectedRequestedPickupDateGt = '';
    this.selectedRequestedPickupDateLte = '';
    this.updateTable();
    this.updateDisplayFilterChips();
    this.saveFiltersToLocalStorage();
  }

  resetCreationDatePicker() {
    this.resetPagination();
    this.requestedCreationDateGt = '';
    this.requestedCreationDateLte = '';
    this.selectedRequestedCreationDateGt = '';
    this.selectedRequestedCreationDateLte = '';
    localStorage.setItem('creationStartDateRemoved', 'true');
    localStorage.setItem('creationEndDateRemoved', 'true');
    this.updateTable();
    this.updateDisplayFilterChips();
    this.saveFiltersToLocalStorage();
  }

  /** Pick-up Date functionality :
   without parameter sets standardDate to today,
   with parameter adds the toAddDays to current date.
   The result date will be yyyy-mm-dd */
  getStandardDate(toAddDays: number = 0): string {
    let date = new Date();
    date.setDate(date.getDate() + toAddDays);

    // Adjust for weekends only if the resulting date falls on the weekend
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 6) {
      // Saturday, move to Monday
      date.setDate(date.getDate() + 2);
    } else if (dayOfWeek === 0) {
      // Sunday, move to Monday
      date.setDate(date.getDate() + 1);
    }

    // Format as YYYY-MM-DD in local time (without time zone conversion)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  onNewOrderClick() {
    localStorage.removeItem("seePaxOrderDetails");
    localStorage.removeItem("paxOrderToUseAsTemplate");
    localStorage.removeItem("paxOrderForEdit");
  }

  onSeeOrderDetailsClick(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("seePaxOrderDetails", JSON.stringify(selectedOrder[0]));
    this.saveCurrentOffsetToLocalStorage();
    this.router.navigate(['/order-details']);
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  onOrderStatusSelect(selectedStatuses: string[]) {
    this.resetPagination();
    this.selectedStatuses = selectedStatuses;
    this.orderStatus = this.selectedStatuses.join(',');
    this.updateDisplayFilterChips();
    this.updateTable();
  }

  onPendingParcelClick(item: any) {
    if (item.statusKey !== 'PARCELS_CREATED') {
        this.showAlert(this.formatShipItErrors(item));
    }
  }

  getShipItValidationErrors(paxOrderKey: string) {
    this.paxOrderService.getShipItResponse(paxOrderKey).subscribe({
      next: (v) => this.paxOrderValdationsErrorList[paxOrderKey]= v,
      error: (e) => this.paxOrderValdationsErrorList[paxOrderKey]= e
    })
  }


  onEditOrderClick(item: any) {
    let selectedOrder = this.ordersList.filter((order: any) => order['paxOrderKey'] === item.paxOrderKey);
    localStorage.setItem("paxOrderForEdit", JSON.stringify(selectedOrder[0]));
    localStorage.setItem("paxOrderValdationsErrorList",  JSON.stringify(this.paxOrderValdationsErrorList[item.paxOrderKey]));
    this.saveCurrentOffsetToLocalStorage();
    this.router.navigate(['/edit-order']);
  }

  formatShipItErrors(item: any): string {
    let errors = this.paxOrderValdationsErrorList[item.paxOrderKey];

    if (!errors.hasOwnProperty('validationErrors')) {
      return "Not allowed to see the validation errors for this order.";
    }

    if (errors['validationErrors'].length < 1) {
      return "Order is still being processed, check again later."
    }

    var formattedErrors = "The following values contain validation errors and need to be resolved: \n\n";

    return formattedErrors += errors['validationErrors'].map((error: any) =>
      `${this.replaceTechnicalWithUINames(error.fieldName)}: ${error.fieldValue}`
    ).join('\n\n');
  }

  replaceTechnicalWithUINames(fieldName: string): string {
    return fieldName.replace("sender", "requester").replace("recipient", "pickup");
  }

  onOptionsClick(event: MouseEvent, index: number) {
    event.stopPropagation();
    this.popoverIndex = this.popoverIndex === index ? null : index;
    if (this.popoverIndex !== null) {
      document.addEventListener('click', this.documentClickListener);
    } else {
      document.removeEventListener('click', this.documentClickListener);
    }
  }

  documentClickListener = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.popup') && !target.closest('.three-points-settings-icon')) {
      this.popoverIndex = null;
      document.removeEventListener('click', this.documentClickListener);
    }
  };

  @ViewChild('alertPopup') alertPopup: PopupMessageComponent;

  showAlert(message: any): void {
    this.alertPopup.showMessage(message);
  }

  /**Resizing logic */
  startResize(event: MouseEvent, th: HTMLElement): void {
    this.isResizing = true;
    this.startingX = event.pageX;
    this.startingWidth = th.offsetWidth;

    this.resizingMouseMove = this.performResize.bind(this, th);
    this.resizingMouseUp = this.stopResize.bind(this);

    document.addEventListener('mousemove', this.resizingMouseMove);
    document.addEventListener('mouseup', this.resizingMouseUp);
  }

  performResize(th: HTMLElement, event: MouseEvent): void {
    if (this.isResizing) {
      const movementX = event.pageX - this.startingX;
      th.style.width = `${this.startingWidth + movementX}px`;
    }
  }

  stopResize(): void {
    this.isResizing = false;

    if (this.resizingMouseMove) {
      document.removeEventListener('mousemove', this.resizingMouseMove);
    }
    if (this.resizingMouseUp) {
      document.removeEventListener('mouseup', this.resizingMouseUp);
    }
  }

  onParcelNumberSortingClick() {
    this.resetPagination();
    const ascendingKey = "parcelnumber";
    const descendingKey = "-parcelnumber";
    const existingIndex = this.sortingList.findIndex(item => item === ascendingKey || item === descendingKey);

    if (this.isParcelNumberAscendingSortingActive) {
        this.isParcelNumberAscendingSortingActive = false;
        this.sortingList.splice(existingIndex, 1);
    } else if (this.isParcelNumberDescendingSortingActive) {
        this.isParcelNumberDescendingSortingActive = false;
        this.isParcelNumberAscendingSortingActive = true;
        this.sortingList[existingIndex] = ascendingKey;
    } else {
        this.isParcelNumberDescendingSortingActive = true;
        if (existingIndex > -1) {
            this.sortingList[existingIndex] = descendingKey;
        } else {
            this.sortingList.push(descendingKey);
        }
    }

    this.updateActiveSortings()
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }

  onCreationDateSortingClick() {
    this.resetPagination();
    const ascendingKey = "inserttimestamp";
    const descendingKey = "-inserttimestamp";
    const existingIndex = this.sortingList.findIndex(item => item === ascendingKey || item === descendingKey);

    if (this.isCreationDateAscendingSortingActive) {
        this.isCreationDateAscendingSortingActive = false;
        this.sortingList.splice(existingIndex, 1);
    } else if (this.isCreationDateDescendingSortingActive) {
        this.isCreationDateDescendingSortingActive = false;
        this.isCreationDateAscendingSortingActive = true;
        this.sortingList[existingIndex] = ascendingKey;
    } else {
        this.isCreationDateDescendingSortingActive = true;
        if (existingIndex > -1) {
            this.sortingList[existingIndex] = descendingKey;
        } else {
            this.sortingList.push(descendingKey);
        }
    }

    this.updateActiveSortings()
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }

  onRequesterLocationSortingClick() {
    this.resetPagination();
    const ascendingKey = "requesterlocation";
    const descendingKey = "-requesterlocation";
    const existingIndex = this.sortingList.findIndex(item => item === ascendingKey || item === descendingKey);

    if (this.isRequesterLocationAscendingSortingActive) {
        this.isRequesterLocationAscendingSortingActive = false;
        this.sortingList.splice(existingIndex, 1);
    } else if (this.isRequesterLocationDescendingSortingActive) {
        this.isRequesterLocationDescendingSortingActive = false;
        this.isRequesterLocationAscendingSortingActive = true;
        this.sortingList[existingIndex] = ascendingKey;
    } else {
        this.isRequesterLocationDescendingSortingActive = true;
        if (existingIndex > -1) {
            this.sortingList[existingIndex] = descendingKey;
        } else {
            this.sortingList.push(descendingKey);
        }
    }

    this.updateActiveSortings()
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }

  onPickupLocationSortingClick() {
    this.resetPagination();
    const ascendingKey = "pickuplocation";
    const descendingKey = "-pickuplocation";
    const existingIndex = this.sortingList.findIndex(item => item === ascendingKey || item === descendingKey);

    if (this.isPickupLocationAscendingSortingActive) {
        this.isPickupLocationAscendingSortingActive = false;
        this.sortingList.splice(existingIndex, 1);
    } else if (this.isPickupLocationDescendingSortingActive) {
        this.isPickupLocationDescendingSortingActive = false;
        this.isPickupLocationAscendingSortingActive = true;
        this.sortingList[existingIndex] = ascendingKey;
    } else {
        this.isPickupLocationDescendingSortingActive = true;
        if (existingIndex > -1) {
            this.sortingList[existingIndex] = descendingKey;
        } else {
            this.sortingList.push(descendingKey);
        }
    }

    this.updateActiveSortings()
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }


  onDeliveryLocationSortingClick() {
    this.resetPagination();
    const ascendingKey = "deliverylocation";
    const descendingKey = "-deliverylocation";
    const existingIndex = this.sortingList.findIndex(item => item === ascendingKey || item === descendingKey);

    if (this.isDeliveryLocationAscendingSortingActive) {
        this.isDeliveryLocationAscendingSortingActive = false;
        this.sortingList.splice(existingIndex, 1);
    } else if (this.isDeliveryLocationDescendingSortingActive) {
        this.isDeliveryLocationDescendingSortingActive = false;
        this.isDeliveryLocationAscendingSortingActive = true;
        this.sortingList[existingIndex] = ascendingKey;
    } else {
        this.isDeliveryLocationDescendingSortingActive = true;
        if (existingIndex > -1) {
            this.sortingList[existingIndex] = descendingKey;
        } else {
            this.sortingList.push(descendingKey);
        }
    }

    this.updateActiveSortings()
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }

  updateActiveSortings() {
    this.activeSortings = [];

    this.sortingList.forEach(sortKey => {
      const isDescending = sortKey.startsWith('-');
      const cleanKey = isDescending ? sortKey.substring(1) : sortKey;
      const displayKey = this.getDisplayKeyForSort(cleanKey);
      this.activeSortings.push({
        key: displayKey,
        order: isDescending ? 'desc' : 'asc'
      });
    });
  }

  getDisplayKeyForSort(key: string): string {
    const displayKeys: { [key: string]: string } = {
      parcelnumber: 'Parcel Number',
      inserttimestamp: 'Creation Date',
      requesterlocation: 'Requester Location',
      pickuplocation: 'Pick-up Location',
      deliverylocation: 'Delivery Location'
    };

    return displayKeys[key] || key;
  }

  removeSortingChips(sort: { key: string, order: 'asc' | 'desc' }) {
    this.resetPagination();
    if (sort.key === 'Remove All Sortings') {
      this.removeAllSorting();
      return;
    }

    const keyMapping: { [key: string]: string } = {
      'Parcel Number': 'parcelnumber',
      'Creation Date': 'inserttimestamp',
      'Requester Location': 'requesterlocation',
      'Pick-up Location': 'pickuplocation',
      'Delivery Location': 'deliverylocation',
    };

    const actualKey = keyMapping[sort.key] || sort.key;
    const formattedKey = sort.order === 'asc' ? actualKey : `-${actualKey}`;

    const index = this.sortingList.indexOf(formattedKey);
    if (index > -1) {
      this.sortingList.splice(index, 1);
    }

    switch (actualKey) {
      case 'parcelnumber':
        this.isParcelNumberAscendingSortingActive = false;
        this.isParcelNumberDescendingSortingActive = false;
        break;
      case 'inserttimestamp':
        this.isCreationDateAscendingSortingActive = false;
        this.isCreationDateDescendingSortingActive = false;
        break;
      case 'requesterlocation':
        this.isRequesterLocationAscendingSortingActive = false;
        this.isRequesterLocationDescendingSortingActive = false;
        break;
      case 'pickuplocation':
        this.isPickupLocationAscendingSortingActive = false;
        this.isPickupLocationDescendingSortingActive = false;
        break;
      case 'deliverylocation':
        this.isDeliveryLocationAscendingSortingActive = false;
        this.isDeliveryLocationDescendingSortingActive = false;
        break;
    }

    this.updateActiveSortings();
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }

  removeAllSorting() {
    this.resetPagination();
    this.sortingList = [];
    this.isParcelNumberAscendingSortingActive = false;
    this.isParcelNumberDescendingSortingActive = false;
    this.isCreationDateAscendingSortingActive = false;
    this.isCreationDateDescendingSortingActive = false;
    this.isRequesterLocationAscendingSortingActive = false;
    this.isRequesterLocationDescendingSortingActive = false;
    this.isPickupLocationAscendingSortingActive = false;
    this.isPickupLocationDescendingSortingActive = false;
    this.isDeliveryLocationAscendingSortingActive = false;
    this.isDeliveryLocationDescendingSortingActive = false;

    this.updateActiveSortings();
    this.updateTable();
    this.saveSortingChipsToLocalStorage();
  }


  saveSortingChipsToLocalStorage() {
    const sortingState = {
      sortingList: this.sortingList,
      isParcelNumberAscendingSortingActive: this.isParcelNumberAscendingSortingActive,
      isParcelNumberDescendingSortingActive: this.isParcelNumberDescendingSortingActive,
      isCreationDateAscendingSortingActive: this.isCreationDateAscendingSortingActive,
      isCreationDateDescendingSortingActive: this.isCreationDateDescendingSortingActive,
      isRequesterLocationAscendingSortingActive: this.isRequesterLocationAscendingSortingActive,
      isRequesterLocationDescendingSortingActive: this.isRequesterLocationDescendingSortingActive,
      isPickupLocationAscendingSortingActive: this.isPickupLocationAscendingSortingActive,
      isPickupLocationDescendingSortingActive: this.isPickupLocationDescendingSortingActive,
      isDeliveryLocationAscendingSortingActive: this.isDeliveryLocationAscendingSortingActive,
      isDeliveryLocationDescendingSortingActive: this.isDeliveryLocationDescendingSortingActive,
    };

    localStorage.setItem('sortingChipsStateForMyOrders', JSON.stringify(sortingState));
  }

  loadSortingChipsFromLocalStorage() {
    const storedSortingState = localStorage.getItem('sortingChipsStateForMyOrders');

    if (storedSortingState) {
      const sortingState = JSON.parse(storedSortingState);

      this.sortingList = sortingState.sortingList || [];
      this.isParcelNumberAscendingSortingActive = sortingState.isParcelNumberAscendingSortingActive || false;
      this.isParcelNumberDescendingSortingActive = sortingState.isParcelNumberDescendingSortingActive || false;
      this.isCreationDateAscendingSortingActive = sortingState.isCreationDateAscendingSortingActive || false;
      this.isCreationDateDescendingSortingActive = sortingState.isCreationDateDescendingSortingActive || false;
      this.isRequesterLocationAscendingSortingActive = sortingState.isRequesterLocationAscendingSortingActive || false;
      this.isRequesterLocationDescendingSortingActive = sortingState.isRequesterLocationDescendingSortingActive || false;
      this.isPickupLocationAscendingSortingActive = sortingState.isPickupLocationAscendingSortingActive || false;
      this.isPickupLocationDescendingSortingActive = sortingState.isPickupLocationDescendingSortingActive || false;
      this.isDeliveryLocationAscendingSortingActive = sortingState.isDeliveryLocationAscendingSortingActive || false;
      this.isDeliveryLocationDescendingSortingActive = sortingState.isDeliveryLocationDescendingSortingActive || false;
    }

    this.updateActiveSortings();
  }
}
